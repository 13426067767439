import { Button, styled } from '@mui/material'

export const TiaaraBtn = styled(Button)(({ theme }) => ({
  background: 'var(--primary-color)',
  color: '#FFF',
  borderRadius: theme.typography.pxToRem(3),
  fontSize: theme.typography.pxToRem(14),
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(22)}`,
  border: 0,
  fontWeight: 700,
  letterSpacing: theme.typography.pxToRem(0.5),
  fontFamily: 'var(--accent-font)',
  textTransform: 'capitalize',
  transition: 'all 3s ease',
  height: theme.typography.pxToRem(40),

  '&:hover': {
    background: '#FFF',
    color: 'var(--primary-color)',
    border: `${theme.typography.pxToRem(1)} solid var(--primary-color)`,
    height: theme.typography.pxToRem(40),
  },

  '&:disabled': {
    background: '#cccccc',
    color: '#666666',
    height: theme.typography.pxToRem(40),
  },
}))

export const DrawBtn = styled(Button)(({ theme }) => ({
  font: 'var(--accent-font)',
  backgroundImage: 'url(/BtnBrushBG.png)',
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  color: 'var(--primary-color)',
  border: 0,
  padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(80)} ${theme.typography.pxToRem(14)} ${theme.typography.pxToRem(80)}`,
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(14),
  lineHeight: 1.2,
  fontWeight: 700,
  borderRadius: theme.typography.pxToRem(4),
  cursor: 'pointer',
  letterSpacing: theme.typography.pxToRem(0.5),
  verticalAlign: 'middle',
  transition: 'all 3s ease',
  textTransform: 'capitalize',
}))

export const TiaaraGrayShadeBtn = styled(Button)(({ theme }) => ({
  background: '#9B9CA5',
  color: '#FFF',
  borderRadius: theme.typography.pxToRem(3),
  fontSize: theme.typography.pxToRem(14),
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(22)}`,
  border: 0,
  fontWeight: 700,
  letterSpacing: theme.typography.pxToRem(0.5),
  fontFamily: 'var(--accent-font)',
  textTransform: 'capitalize',
  transition: 'all 3s ease',
  height: theme.typography.pxToRem(40),

  '&:hover': {
    background: '#FFF',
    color: '#9B9CA5',
    border: `${theme.typography.pxToRem(1)} solid #9B9CA5`,
    height: theme.typography.pxToRem(40),
  },

  '&:disabled': {
    background: '#cccccc',
    color: '#666666',
    height: theme.typography.pxToRem(40),
  },
}))

export const TiaaraPinkishShadeBtn = styled(Button)(({ theme }) => ({
  background: '#F47F7F',
  color: '#FFF',
  borderRadius: theme.typography.pxToRem(3),
  fontSize: theme.typography.pxToRem(14),
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(22)}`,
  border: 0,
  fontWeight: 700,
  letterSpacing: theme.typography.pxToRem(0.5),
  fontFamily: 'var(--accent-font)',
  textTransform: 'capitalize',
  transition: 'all 3s ease',
  height: theme.typography.pxToRem(40),

  '&:hover': {
    background: '#FFF',
    color: '#F47F7F',
    border: `${theme.typography.pxToRem(1)} solid #F47F7F`,
    height: theme.typography.pxToRem(40),
  },

  '&:disabled': {
    background: '#cccccc',
    color: '#666666',
    height: theme.typography.pxToRem(40),
  },
}))
