import React, { FC, ReactNode, useEffect } from 'react'

// Nextjs
import { useRouter } from 'next/router'

// MUI
import { Box } from '@mui/material'

// Hooks
import { useAuth } from '@/hooks/use-auth'

type AuthGuardProps = {
  children: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = props => {
  const { children } = props

  const router = useRouter()

  const { isAuthenticated } = useAuth()

  useEffect(() => {
    ;(() => {
      if (!router.isReady) {
        return
      }

      if (!isAuthenticated) {
        router.push({
          pathname: '/auth/login',
          query: { returnUrl: router.asPath },
        })
      }
    })()
  }, [router.isReady])

  return <Box>{children}</Box>
}

export default AuthGuard
