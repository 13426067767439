import { Box, styled } from '@mui/material'

const TiaaraLogoSplash = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
  },
}))

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'var(--secondary-color)',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 2000,
    }}
  >
    <TiaaraLogoSplash src="/Logos/png/Tiaara_Logo_48_48.png" srcSet="/Logos/png/Tiaara_Logo_48_48.png" alt="Tiaara" />
  </Box>
)
