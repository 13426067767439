import React, { FC } from 'react'

// Nextjs
import { useRouter } from 'next/router'

// MUI
import { Badge, Box, IconButton, Tooltip, Typography } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

// Redux
import { useSelector } from 'react-redux'

// Redux Slice Utils
import { cartItemSelector } from '@/Redux/utils'

// useAuth Hook
import { useAuth } from '@/hooks/use-auth'

// UI
import { NextLink } from '@/ui'

const DesktopNavComponents: FC = () => {
  const { isAuthenticated, logout } = useAuth()

  const router = useRouter()

  const cartInfo = useSelector(cartItemSelector)

  return (
    <Box>
      {isAuthenticated ? (
        <Box sx={{ display: 'flex' }}>
          <NextLink href="/cart" aria-label="Cart in Tiaara">
            <IconButton size="large" aria-label="Cart" color="inherit">
              <Tooltip title="View Cart">
                <Badge badgeContent={cartInfo.count} color="error">
                  <ShoppingCartIcon fontSize="medium" sx={{ color: '#222' }} />
                </Badge>
              </Tooltip>
            </IconButton>
          </NextLink>
          <NextLink href="/account/profile" aria-label="User Profile in Tiaara">
            <IconButton size="large" aria-label="User Profile in Tiaara" color="inherit">
              <Tooltip title="My Profile">
                <AccountCircle fontSize="medium" sx={{ color: '#222' }} />
              </Tooltip>
            </IconButton>
          </NextLink>
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="Logout" arrow>
              <IconButton
                size="large"
                aria-label="Cart"
                color="inherit"
                onClick={() => {
                  logout?.()
                  router.push('/')
                }}
              >
                <LogoutIcon sx={{ color: '#222' }} />
              </IconButton>
            </Tooltip>
            <p>Logout</p>
          </Box>
        </Box>
      ) : (
        <Box>
          <NextLink href="/auth/login" aria-label="Login to Tiaara" title="Login">
            <Typography sx={{ color: '#222' }}>Login</Typography>
          </NextLink>
        </Box>
      )}
    </Box>
  )
}

export default DesktopNavComponents
