import React, { FC, ReactNode } from 'react'

// Navbar
import PrimarySearchBar from './Navbar'

// Footer
import Footer from './Footer'

type LayoutProps = {
  children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <PrimarySearchBar />

      {children}

      <Footer />
    </>
  )
}

export default Layout
