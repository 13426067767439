// interface
import { CartRespProps } from '@/interface'

type CartProps = {
  count: number
  cartData: CartRespProps[]
}

type ReduxStoreStateProps = {
  cart: CartProps
  socket: any
  user: {
    name: string
    email: string
  }
}

export const cartItemSelector = (state: ReduxStoreStateProps) => state.cart
export const socketSelector = (state: ReduxStoreStateProps) => state.socket
export const userSelector = (state: ReduxStoreStateProps) => state.user
