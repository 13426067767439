import { styled, Typography } from '@mui/material'

export const SectionHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--quicksand-font)',
  fontSize: theme.typography.pxToRem(22),
  fontWeight: 600,
  lineHeight: 1.2,
  color: '#222',

  '& span': {
    color: 'var(--primary-color)',
  },
}))

export const WeHaveFoundText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: theme.typography.pxToRem(24),
  color: '#465b52',
  margin: 0,
  fontFamily: 'var(--source-code-pro-font)',

  '& strong': {
    margin: `0 ${theme.typography.pxToRem(3)}`,
    color: 'var(--primary-color)',
    fontWeight: 600,
    textDecoration: 'underline',
  },
}))
