import '@/styles/globals.css'

// Nextjs
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'

// react-hot-toast
import { Toaster } from 'react-hot-toast'

// nProgress
import nProgress from 'nprogress'

// @emotion
import { CacheProvider } from '@emotion/react'

// Redux
import { Provider } from 'react-redux'

// Redux Persist
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

// Redux Store
import { store } from '@/Redux'

// AuthContext
import { AuthConsumer, AuthProvider } from '@/context/jwt-context'

// Splash Screen
import { SplashScreen } from '@/components/splash-screen'

// AuthGuard
import AuthGuard from '@/AuthGuard'

// Layout
import Layout from '@/Layout'

// Utils
import { createEmotionCache } from '@/utils'

Router.events.on('routeChangeStart', nProgress.start)
Router.events.on('routeChangeError', nProgress.done)
Router.events.on('routeChangeComplete', nProgress.done)

const clientSideEmotionCache = createEmotionCache()

const persistor = persistStore(store)

interface CustomAppProps extends Omit<AppProps, 'Component'> {
  emotion: any
  Component: AppProps['Component'] & { isAuthRequired: boolean }
}

const App = (props: CustomAppProps) => {
  const { Component, emotion = clientSideEmotionCache, pageProps } = props

  return (
    <CacheProvider value={emotion}>
      <Head>
        <title>Tiaara</title>
        <link rel="icon" href="/Logos/webp/Tiaara_Logo_114_114.webp" />
      </Head>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthProvider>
            <Toaster position="top-right" reverseOrder={false} />
            <AuthConsumer>
              {auth =>
                !auth.isInitialized ? (
                  <SplashScreen />
                ) : (
                  <Layout>
                    {Component.isAuthRequired ? (
                      <AuthGuard>
                        <Component {...pageProps} />
                      </AuthGuard>
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </Layout>
                )
              }
            </AuthConsumer>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </CacheProvider>
  )
}

export default App
