/* eslint-disable react/jsx-no-duplicate-props */
import React, { ChangeEvent, FC, useRef } from 'react'

// Nextjs
import { useRouter } from 'next/router'

// MUI
import { Box, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const SearchComponent: FC = () => {
  const router = useRouter()

  const queryRef = useRef<null | any>(null)

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    router.push({
      pathname: '/search',
      query: `query=${queryRef.current.value}`,
    })
  }

  return (
    <Box component="form" onSubmit={handleSearch}>
      <TextField
        fullWidth
        variant="standard"
        placeholder="Search  Products..."
        inputProps={{ 'aria-label': 'search', ref: queryRef }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" sx={{ color: '#222' }} />
            </InputAdornment>
          ),
        }}
        spellCheck={false}
      />
    </Box>
  )
}

export default SearchComponent
