// interface
import { AllCartsItemOfOrderResp, CartRespProps } from '@/interface'

export const calculateTotalPrice = (arr: CartRespProps[], selectedCart: string[]) => {
  if (selectedCart.length !== 0) {
    const selectedItems = arr.filter(ids => {
      return selectedCart.some(id => ids.cartDetailsId.includes(id))
    })

    return selectedItems.reduce((acc, item) => {
      const price = item.pQuantity * item.perProductPrice
      return acc + price
    }, 0)
  }

  return arr.reduce((acc, item) => {
    const price = item.pQuantity * item.perProductPrice
    return acc + price
  }, 0)
}

export const finalCheckoutPriceBreakdown = (arr: AllCartsItemOfOrderResp) => {
  const totalPriceWithoutGST: number = arr.cartDetails.reduce((acc, item) => {
    const price = item.pQuantity * item.perProductPrice
    return acc + price
  }, 0)

  const totalGSTAmount: number = arr.cartDetails.reduce((acc, item) => {
    const price = (item.perProductPrice * item.productDetail.gstPercentage) / 100
    return acc + price
  }, 0)

  const totalPriceWithGST: number = arr.cartDetails.reduce((acc, item) => {
    const price = item.perProductPrice + (item.perProductPrice * item.productDetail.gstPercentage) / 100
    return acc + price
  }, 0)

  const paymentGatewayFee: number = Math.ceil((totalPriceWithoutGST * 2) / 100)

  const totalPrice: number = totalPriceWithGST + paymentGatewayFee

  return { totalPriceWithoutGST, totalGSTAmount, totalPriceWithGST, paymentGatewayFee, totalPrice }
}
