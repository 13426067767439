import { createSlice } from '@reduxjs/toolkit'

type initialValueProps = {
  name: string
  email: string
}

const initialValue: initialValueProps = {
  name: '',
  email: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: initialValue,
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload
    },
    logout: state => {
      state.value = initialValue
    },
  },
})

export const { login, logout } = userSlice.actions

export default userSlice.reducer
