// Redux Toolkit
import { configureStore } from '@reduxjs/toolkit'

// Redux
import { combineReducers } from 'redux'

// Redux Persist
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

// Redux Thunk
import thunk from 'redux-thunk'

// Slices
import userSlice from './slices/user-slice'
import socketSlice from './slices/socket-slice'
import cartSlice from './slices/cart-slice'

// Reducers
const reducers = combineReducers({
  user: userSlice,
  socket: socketSlice,
  cart: cartSlice,
})

// Persist Config
const persistConfig = {
  key: 'rootRedux',
  storage,
}

// Persist Reducer
const persistedReducer = persistReducer(persistConfig, reducers)

// Store
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})
