import Link from 'next/link'

// MUI
import { styled } from '@mui/material'

export const NextLink = styled(Link)({
  color: '#4f5d77',
  textDecoration: 'none',
  fontFamily: 'var(--accent-font)',

  ':hover': {
    color: '#4f5d77',
    textDecoration: 'none',
  },
})
