import { Badge, Box, IconButton, Menu, MenuItem } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

// UI
import { NextLink } from '@/ui'
import { NextRouter } from 'next/router'

type RenderMobileMenuProps = {
  mobileMenuId: string
  isMobileMenuOpen: boolean
  handleMobileMenuClose: () => void
  isAuthenticated: boolean
  count: number
  mobileMoreAnchorEl: any
  logout: () => void
  router: NextRouter
}

export const renderMobileMenu = ({ mobileMoreAnchorEl, mobileMenuId, isMobileMenuOpen, handleMobileMenuClose, isAuthenticated, count, logout, router }: RenderMobileMenuProps) => (
  <Menu
    anchorEl={mobileMoreAnchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    id={mobileMenuId}
    keepMounted
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={isMobileMenuOpen}
    onClose={handleMobileMenuClose}
  >
    {isAuthenticated ? (
      <Box>
        <MenuItem>
          <NextLink href="/cart" aria-label="Cart in Tiaara" sx={{ display: 'flex' }}>
            <IconButton size="large" aria-label="Cart" color="inherit">
              <Badge badgeContent={count} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <p>Cart</p>
          </NextLink>
        </MenuItem>
        <MenuItem>
          <NextLink href="/account/profile" aria-label="My Profile on Tiaara" sx={{ display: 'flex' }}>
            <IconButton size="large" aria-label="My Profile on Tiaara" color="inherit">
              <AccountCircle />
            </IconButton>
            <p>My Profile</p>
          </NextLink>
        </MenuItem>
        <MenuItem>
          <IconButton
            size="large"
            aria-label="Cart"
            color="inherit"
            onClick={() => {
              logout?.()
              router.push('/')
            }}
          >
            <LogoutIcon />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      </Box>
    ) : (
      <Box>
        <MenuItem>
          <NextLink href="/auth/login" aria-label="Login in Tiaara" sx={{ display: 'flex' }}>
            <p>Login</p>
          </NextLink>
        </MenuItem>
      </Box>
    )}
  </Menu>
)
