import React, { FC } from 'react'

// Nextjs
import Image from 'next/image'

// MUI
import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { List, ListItem } from '@mui/joy'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'

// Hooks
import { useAuth } from '@/hooks/use-auth'

// UI
import { NextLink } from '@/ui'
import { AboutMyAccountH5, AddressNumberEmailText, ContactFollowH5 } from './styles'

// Image from Public DIr
import TiaaraLogo120 from '../../../public/Logos/webp/Tiaara_Logo_120_120.webp'

const Footer: FC = () => {
  const { isAuthenticated } = useAuth()

  return (
    <Box>
      <Divider sx={{ mb: 3 }} />
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid item md={3} sm={4} xs={12}>
            <Image src={TiaaraLogo120} alt="Tiaara" title="Tiaara" />
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <AboutMyAccountH5 variant="h5">About</AboutMyAccountH5>

            <List aria-label="discover-footer-links">
              <ListItem>
                <NextLink href="/about-us" aria-label="About Tiaara" title="About Tiaara">
                  About Us
                </NextLink>
              </ListItem>
              <ListItem>
                <NextLink href="/contact-us" aria-label="Contact Tiaara" title="Contact Tiaara">
                  Contact Us
                </NextLink>
              </ListItem>
            </List>
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <AboutMyAccountH5 variant="h5">My Account</AboutMyAccountH5>

            {isAuthenticated ? (
              <List aria-label="discover-footer-links">
                <ListItem>
                  <NextLink href="/account/profile" aria-label="My Profile on Tiaara" title="My Profile">
                    My Profile
                  </NextLink>
                </ListItem>
              </List>
            ) : (
              <List aria-label="discover-footer-links">
                <ListItem>
                  <NextLink href="/auth/login" aria-label="Sign In to Tiaara" title="Sign In to Tiaara">
                    Sign In
                  </NextLink>
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <Box>
              <AboutMyAccountH5 variant="h5">Follow Us</AboutMyAccountH5>

              <List aria-label="discover-social-media-links">
                <ListItem>
                  <Stack direction="row" gap={2}>
                    <NextLink href="https://facebook.com" aria-label="Follow Tiaara on Facebook" title="Follow Tiaara on Facebook">
                      <FacebookIcon fontSize="large" color="primary" />
                    </NextLink>

                    <NextLink href="https://www.instagram.com/tiaara_rediscoverelegance/" aria-label="Follow Tiaara on Instagram" title="Follow Tiaara on Instagram" target="_blank">
                      <InstagramIcon fontSize="large" color="error" />
                    </NextLink>
                  </Stack>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        <Box>
          <Box>
            <ContactFollowH5 variant="h5">Contact</ContactFollowH5>

            <Box>
              <AddressNumberEmailText variant="body1" variantMapping={{ body1: 'p' }}>
                <AddressNumberEmailText variant="body2" variantMapping={{ body2: 'strong' }}>
                  Address:
                </AddressNumberEmailText>{' '}
                No. 42/2/34, Rajani Towers, 27th Cross, 7th B Main, 4th Block Jayanagar, Bangalore - 560 011
              </AddressNumberEmailText>
            </Box>

            <Box>
              <AddressNumberEmailText variant="body1" variantMapping={{ body1: 'p' }}>
                <AddressNumberEmailText variant="body2" variantMapping={{ body2: 'strong' }}>
                  Phone:
                </AddressNumberEmailText>{' '}
                <NextLink href="tel:+919845852121" aria-label="Call Tiaara" title="Call Tiaara">
                  +91 - 98458 52121
                </NextLink>
              </AddressNumberEmailText>
            </Box>

            <Box>
              <AddressNumberEmailText variant="body1" variantMapping={{ body1: 'p' }}>
                <AddressNumberEmailText variant="body2" variantMapping={{ body2: 'strong' }}>
                  Email:
                </AddressNumberEmailText>{' '}
                <NextLink href="mailto:info@tiaara.in" aria-label="Email Tiaara" title="Email Tiaara">
                  info@tiaara.in
                </NextLink>
              </AddressNumberEmailText>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ mb: 3 }}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
            <Grid item>
              <Typography variant="body2" variantMapping={{ body2: 'p' }} sx={{ fontFamily: 'var(--source-code-pro-font)', fontSize: '1rem' }}>
                &copy; {new Date().getFullYear()} Tiaara. All Rights Reserved.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" variantMapping={{ body2: 'p' }} sx={{ fontFamily: 'var(--source-code-pro-font)', fontSize: '1rem' }}>
                Website Developed &amp; Maintained by{' '}
                <NextLink
                  href="https://mediateck.vercel.app"
                  target="_blank"
                  aria-label="Media Teck"
                  title="Media Teck"
                  sx={{ textDecoration: 'underline', '&:hover': { textDecoration: 'underline' } }}
                >
                  Media Teck
                </NextLink>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
