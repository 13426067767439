/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useEffect, useReducer } from 'react'

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const handlers: any = {
  INITIALIZE: (state: any, action: any) => {
    const { isAuthenticated, user } = action.payload

    return { ...state, isAuthenticated, isInitialized: true, user }
  },
  AUTHENTICATED: (state: any, action: any) => {
    const { user } = action.payload

    return { ...state, isAuthenticated: true, user }
  },
  LOGOUT: (state: any) => ({ ...state, isAuthenticated: false, user: null }),
}

const reducer = (state: any, action: any) => (handlers[action.type] ? handlers[action.type](state, action) : state)

export const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  changeToAuthenticated: () => Promise.resolve(),
  logout: () => Promise.resolve(),
})

export const AuthProvider = (props: any) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        const userpayload = window.localStorage.getItem('userpayload')

        if (userpayload) {
          //   const user = await authApi.me(userpayload)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: 'user',
            },
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    }

    initialize()
  }, [])

  const changeToAuthenticated = async () => {
    dispatch({
      type: 'AUTHENTICATED',
      payload: {
        isAuthenticated: true,
      },
    })
  }

  const logout = async () => {
    localStorage.clear()

    dispatch({ type: 'LOGOUT' })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        changeToAuthenticated,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer
