import { styled, Typography } from '@mui/material'

export const ContactFollowH5 = styled(Typography)(({ theme }) => ({
  margin: `${theme.typography.pxToRem(10)} 0 ${theme.typography.pxToRem(20)} 0`,
  color: '#90908e',
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(17),
  lineHeight: 1.2,
  fontFamily: 'var(--source-code-pro-font)',
}))

export const AboutMyAccountH5 = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--source-code-pro-font)',
  margin: `${theme.typography.pxToRem(15)} 0 ${theme.typography.pxToRem(20)}`,
  fontSize: theme.typography.pxToRem(22),
  fontWeight: 600,
  color: '#222',
  lineHeight: 1.2,
}))

export const AddressNumberEmailText = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--source-code-pro-font)',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: theme.typography.pxToRem(24),
  marginBottom: theme.typography.pxToRem(5),
  color: '#465b52',

  '& strong': {
    fontWeight: 600,
  },
}))
