/* eslint-disable prefer-const */
// React
import React, { FC, useState } from 'react'

// Nextjs
import { useRouter } from 'next/router'

// MUI
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import MoreIcon from '@mui/icons-material/MoreVert'

// Redux
import { useSelector } from 'react-redux'

// Redux Utils
import { cartItemSelector } from '@/Redux/utils/selector'

// useAuth => Hook
import { useAuth } from '@/hooks/use-auth'

// UI
import { NextLink } from '@/ui'

// Navbar Component
import SearchComponent from './SearchComponent'
import DesktopNavComponents from './DesktopComponents'
import { renderMobileMenu } from './MobileMenu'

const PrimarySearchBar: FC = () => {
  const { isAuthenticated, logout } = useAuth()

  const router = useRouter()

  const cartItems = useSelector(cartItemSelector)

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId: string = 'primary-search-account-menu-mobile'

  return (
    <>
      <AppBar position="static" component="header" sx={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #222' }}>
        <Toolbar>
          <NextLink href="/" aria-label="Tiaara Home Page" title="Tiaara">
            <Typography variant="h6" sx={{ fontFamily: 'var(--playfair-display-sc-font)', textTransform: 'uppercase', color: 'var(--primary-color)', fontSize: { md: '1rem', xs: '0.8rem' } }}>
              Tiaara
            </Typography>
          </NextLink>
          <Box sx={{ flexGrow: 1 }} />
          <SearchComponent />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 1 }}>
            <DesktopNavComponents />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon sx={{ color: '#222' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu({
        count: cartItems.count,
        handleMobileMenuClose,
        isAuthenticated,
        isMobileMenuOpen,
        mobileMenuId,
        mobileMoreAnchorEl,
        logout,
        router,
      })}
    </>
  )
}

export default PrimarySearchBar
