/* eslint-disable no-restricted-syntax */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// interface
import { CartRespProps } from '@/interface'

type InitialStateProps = {
  count: number
  cartData: CartRespProps[]
}

const initialState: InitialStateProps = {
  count: 0,
  cartData: [],
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    cartDetail: (state, action: PayloadAction<CartRespProps[]>) => {
      const cartArr: CartRespProps[] = action.payload

      state.count += cartArr.length

      cartArr.filter(carr => state.cartData.push(carr))
    },
    removeItemFromCart: (state, action: PayloadAction<CartRespProps[]>) => {
      const itemsToRemove: CartRespProps[] = action.payload

      for (const itemToRemove of itemsToRemove) {
        const index = state.cartData.findIndex(item => item.cartDetailsId === itemToRemove.cartDetailsId)

        if (index !== -1) {
          state.count -= 1
          state.cartData.splice(index, 1)
        }
      }
    },
  },
})

export const { cartDetail, removeItemFromCart } = cartSlice.actions

export default cartSlice.reducer
